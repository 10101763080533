import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import './PartnsersSlider.css'
import {GetCategories, GetPartners} from "../../Services/service";
import {GetStaticContentBaseUrl} from "../../Services/common";

const PartnersSlider = () => {
    const settings = {
        dots: false, infinite: true, speed: 2000, variableWidth: true, centerMode: true, responsive: [
            {
            breakpoint: 1200, settings: {  arrows: false}
            }
            , {
                breakpoint: 900, settings: {arrows: false}
            }
            , {
                breakpoint: 800, settings: {arrows: false}
            }
            , {
                breakpoint: 650, settings: {arrows: false}
            }
            , {
                breakpoint: 500, settings: { arrows: false}
            }
        ]
    };
    const [data, setData] = useState(null);
    useEffect(() => {
        GetPartners()
            .then(response => { 
                setData(response.data.filter(item=>item.partnerType === 'GamingPartner'))
            })
            .catch(err => {

            });

    }, [])

    return (<>
        <div className={'partnersContainer'}>
            <div className={'partnersTitle'}>
                Our Partners
            </div>
            <div className={'partnersSlider'}>
                <Slider {...settings}>
                    {Array.isArray(data) && data.length > 0 && data.map((item, index) =>
                        <a key={index} href={item.redirectUrl} className={'slider-a'} target={'_blank'}>
                            <img className={'slider-item'} src={GetStaticContentBaseUrl() + item.iconPath}
                                 alt={item.imageAltAttribute}/>
                        </a>)}
                </Slider>
            </div>
        </div>
    </>);
};

export default PartnersSlider;
