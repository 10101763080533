import React from "react";
import { RemoveScroll } from "react-remove-scroll";
import styles from "./Popup.module.css";

const Popup = (props) => {
  return (
    <>
        <div className={styles.contactPopupBackground} onClick={() => props.onClose()} />
        <RemoveScroll/>
        <iframe
          className={styles.iframe}
          src="https://streamable.com/e/p5c4t0?autoplay=1&nocontrols=1"
          frameBorder="0"
          width="100%"
          height="100%"
          allowFullScreen
          allow="autoplay"
        ></iframe>
    </>
  );
};

export default Popup;
