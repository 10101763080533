import React from 'react';
import CustomSlider from "../../../Components/Slider/Slider";
import GamesContainer from "../../../Components/GamesContainer/GamesContainer";
import MobileSlider from "../../../Components/Mobile/MobileSlider/MobileSlider";
import MobileGamesContainer from "../../../Components/Mobile/MobileGamesContainer/MobileGamesContainer";
import XGamesDescription from "../../../Components/Descriptions/XGamesDescription";

const XGames = () => {
    return (
        <>
            <XGamesDescription/>
            <MobileSlider pageName={'XGames'}/>
            <MobileGamesContainer categoryName={'XGames'}/>

        </>
    );
};

export default XGames;
