import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import {GetNewsItems, GetPartners} from "../../../Services/service";
import {useParams} from "react-router";
import {GetStaticContentBaseUrl} from "../../../Services/common";
import styles from './News.module.css'
import PathNavLink from "../../../Components/PathNavLink/PathNavLink";
import Moment from 'moment';
import {Link} from "react-router-dom";
import CustomPagination from "../../../Components/Pagination/CustomPagination";

const News = () => {
    const [data, setData] = useState(null);
    let parameters = new URLSearchParams(window.location.search);
    let category = parameters.get('category');
    useEffect(() => {
        GetNewsItems()
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });

    }, [category])

    const [paginationData, setPaginationData] = useState(null)
    let newses;

    if (category === 'All') {
        newses =
            <div className={styles.newsContainer}>
                {
                    data && data.filter(x => x.category === category || (category === 'All' || category === undefined || category == null)).sort(function (a, b) { return new Date(b.publishDate) - new Date(a.publishDate); }).map((item, index) =>
                        <div key={index} className={styles.newsItemWrapper}>

                            <div className={styles.newsHeadWrapper}>
                                <img className={styles.newsImage} src={GetStaticContentBaseUrl() + item.filePath}
                                    alt={item.imageAltAttribute} />
                                <div className={styles.newsHeadWrapperInfo}>
                                    <h1 className={styles.newsTitle}>{item.newsTitle}</h1>
                                    <div className={styles.publishDate}><span>{Moment(item.publishDate).format("D")} {Moment(item.publishDate).format('MMM')}</span> <span className={styles.monthName}>{Moment(item.publishDate).format('YYYY')}</span></div>
                                </div>
                            </div>
                            <h2 className={styles.newsDescription}>{parse(item.newsDescription.slice(0, 300))} ... <Link to={`/newsItem?newsId=${item.newsId}`} className={styles.seeAll}>See All</Link></h2>
                        </div>
                    )
                }
            </div>
    } else{
        newses =
            <div className={styles.newsContainer}>
                {
                    data && data.filter(x => x.category === category || (category === 'All' || category === undefined || category == null)).sort(function (a, b) { return b.priority - a.priority}).map((item, index) =>
                        <div key={index} className={styles.newsItemWrapper}>

                            <div className={styles.newsHeadWrapper}>
                                <img className={styles.newsImage} src={GetStaticContentBaseUrl() + item.filePath}
                                    alt={item.imageAltAttribute}/>
                                <div className={styles.newsHeadWrapperInfo}>
                                    <h1 className={styles.newsTitle}>{item.newsTitle}</h1>
                                    <div className={styles.publishDate}><span>{Moment(item.publishDate).format("D")} {Moment(item.publishDate).format('MMM')}</span> <span className={styles.monthName}>{Moment(item.publishDate).format('YYYY')}</span></div>
                                </div>
                            </div>
                            <h2 className={styles.newsDescription}>{parse(item.newsDescription.slice(0,300))} ... <Link to={`/newsItem?newsId=${item.newsId}`} className={styles.seeAll}>See All</Link></h2>
                        </div>
                    )   
                }
            </div>
    }
    
    return (    
        <>

            <div className={styles.menuWrapper}>
                <PathNavLink activeClassName={styles.activeNews} exact to={'/news?category=All'}>ALL</PathNavLink>

                <PathNavLink activeClassName={styles.activeNews} exact to={'/news?category=Releases'}>
                    Releases</PathNavLink>
                <PathNavLink activeClassName={styles.activeNews} exact to={'/news?category=Promotions'}>
                    Promotions</PathNavLink>
                <PathNavLink activeClassName={styles.activeNews} exact to={'/news?category=PartnerShips'}>PartnerShips</PathNavLink>
                <PathNavLink activeClassName={styles.activeNews} exact to={'/news?category=Awards'}>Awards</PathNavLink>
                <PathNavLink activeClassName={styles.activeNews} exact to={'/news?category=Events'}>Events</PathNavLink>
                <PathNavLink activeClassName={styles.activeNews} exact to={'/news?category=BlogPosts'}>BlogPosts</PathNavLink>
            </div>
            {newses}
            {/*{paginationData &&  <CustomPagination itemsPerPage={6} data = {data} onPageChange={((data)=>setPaginationData(data))}/>}*/}
        </> 
    );
};

export default News;
    