import React from 'react';
import styles from './ConfirmCookies.module.css'
const ConfirmCookies = (props) => {
    return (
        <div className={styles.confirmContainer}>
          <span>  We use cookies to ensure you get the best gaming experience from SmartSoftGaming.com. By using our site, you accept the use of cookies as outlined in our Privacy Policy</span>
            <button onClick={()=>props.onClose()} className={styles.accept}>Accept</button>
        </div>
    );
};

export default ConfirmCookies;
