import React from 'react';
import {Helmet} from "react-helmet";

const XGamesDescription = () => {
    return (
        <Helmet>
            <meta name="description" content="SmartSoft Gaming gave the foundation to a whole new category of games - XGames. Five successful new game releases in the category were already made in 2021 - JetX3, SpinX, Cappadocia, Cricket, Balloon.  "/>
        </Helmet>
    );
};

export default XGamesDescription;
