import {isMobile} from "react-device-detect";
import {useMedia} from "use-media";
const hostname = process.env.REACT_APP_API_URL;
const staticContentUrl = process.env.REACT_APP_CONTENT_BASE_URL;
const locale = {};

export const GetAPIBaseUrl = () => {
    return IsLocalhost ? 'https://localhost:44356/api' : hostname;
}
export const GetStaticContentBaseUrl = () => {
    return staticContentUrl;
}
export const IsLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const ConsoleLog = (msg) => {
    if (!IsLocalhost) return;
    console.log(msg);
}

export const GetCurrentTime = (time) => {
    let countDownDate = new Date(time).getTime();
    let now = new Date().getTime();

    let distance = countDownDate - now;
    let expired = distance < 0;

    let days = expired ? 0 : Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = expired ? 0 : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = expired ? 0 : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = expired ? 0 : Math.floor((distance % (1000 * 60)) / 1000);

    return {days, hours, minutes, seconds, expired}
}

export const SetItemToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const GetItemFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

export const SetCategoryTitleToDocument = (title) => {
    document.title = title;
}

export const GetGameRedirectUrl = (gameName) => {
    return `https://server.ssg-public.com/SmartSoftGamePlay/authorization.aspx?GameName=${gameName}`
}

export const IsMobileBrowser = () => {
    const minResolution = useMedia("(max-width: 1200px)")
    return isMobile || minResolution;
}