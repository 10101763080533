import React from 'react';
import {Helmet} from "react-helmet";

const RouletteDescription = () => {
    return (
        <Helmet>
            <meta name="description" content="Check out and play our entertaining casino games and find out our Virtual burning roulette, Personal roulette, Mini roulette, live roulette, virtual classic roulette, etc."/>
        </Helmet>
    );
};

export default RouletteDescription;
