import React, {useEffect, useState} from 'react';
import {GetNewsItems, GetPartners} from "../../../Services/service";
import { useParams } from "react-router";
import parse from 'html-react-parser';
import {GetStaticContentBaseUrl} from "../../../Services/common";
import styles from './News.module.css'
import Moment from 'moment';
import {Link, NavLink} from "react-router-dom";

const News = () => {
    const [data, setData] = useState(null);
    let parameters = new URLSearchParams(window.location.search);
    let category = parameters.get('category');
    useEffect(() => {
        GetNewsItems()
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });

    }, [category])

    const [paginationData, setPaginationData] = useState(null)
    const [dropdownData, setDropDownData] = useState({
        'All': '/news?category=All',
        'Releases': '/news?category=Releases',
        'Promotions': '/news?category=Promotions',
        'PartnerShips': '/news?category=PartnerShips',
        'Awards': '/news?category=Awards',
        'Events': '/news?category=Events',
        'BlogPosts': '/news?category=BlogPosts',
    })
    const [selectedItem, setSelectedItem] = useState(Object.keys(dropdownData).find(item => item.replaceAll(/\s/g,'') === category));
    const[showChooser,setShowChooser] = useState(false);
    function handleClickChooserItem(item){
        setSelectedItem(item);
        setShowChooser(false)
    }
    let newses;

    if (category === 'All') {
        newses =
            <div className={styles.newsContainer}>
                {
                    data && data.filter(x => x.category === category || (category === 'All' || category === undefined || category == null)).sort(function(a,b){ return new Date(b.publishDate) - new Date(a.publishDate); }).map((item, index) =>
                        <div key={index} className={styles.newsItemWrapper}>

                            <div className={styles.newsHeadWrapper}>
                                <img className={styles.newsImage} src={GetStaticContentBaseUrl() + item.filePath}
                                    alt={item.imageAltAttribute}/>
                                <div className={styles.newsHeadWrapperInfo}>
                                    <h1 className={styles.newsTitle}>{item.newsTitle}</h1>
                                    <div className={styles.publishDate}><span>{Moment(item.publishDate).format("D")} {Moment(item.publishDate).format('MMM')}</span> <span className={styles.monthName}>{Moment(item.publishDate).format('YYYY')}</span></div>
                                </div>
                            </div>
                            <h2 className={styles.newsDescription}>{parse(item.newsDescription.slice(0,300))} ... <Link to={`/newsItem?newsId=${item.newsId}`} className={styles.seeAll}>See All</Link></h2>
                        </div>
                    )   
                }
            </div>
    } else {
        newses =
            <div className={styles.newsContainer}>
                {
                    data && data.filter(x => x.category === category || (category === 'All' || category === undefined || category == null)).sort(function (a, b) { return b.priority - a.priority}).map((item, index) =>
                        <div key={index} className={styles.newsItemWrapper}>

                            <div className={styles.newsHeadWrapper}>
                                <img className={styles.newsImage} src={GetStaticContentBaseUrl() + item.filePath}
                                    alt={item.imageAltAttribute}/>
                                <div className={styles.newsHeadWrapperInfo}>
                                    <h1 className={styles.newsTitle}>{item.newsTitle}</h1>
                                    <div className={styles.publishDate}><span>{Moment(item.publishDate).format("D")} {Moment(item.publishDate).format('MMM')}</span> <span className={styles.monthName}>{Moment(item.publishDate).format('YYYY')}</span></div>
                                </div>
                            </div>
                            <h2 className={styles.newsDescription}>{parse(item.newsDescription.slice(0,300))} ... <Link to={`/newsItem?newsId=${item.newsId}`} className={styles.seeAll}>See All</Link></h2>
                        </div>
                    )   
                }
            </div>
    }
    return (
        <>

            <div className={styles.menuWrapper}>
                <div className={styles.selectedItem + ' ' + styles[selectedItem]} onClick={()=>setShowChooser(!showChooser)}>{selectedItem}</div>
                {
                 showChooser &&  <div className={styles.chooserSuggestions}>
                        {
                            Object.keys(dropdownData).filter(item=>item !== selectedItem).map((item, index) =>
                                <NavLink key={index} activeClassName={styles.activeNews + ' ' + styles[item]} onClick={()=>handleClickChooserItem(item)} exact
                                         to={dropdownData[item]}>{item}</NavLink>
                            )
                        }
                    </div>
                }
            </div>
            {newses}
            {/*{paginationData &&  <CustomPagination itemsPerPage={6} data = {data} onPageChange={((data)=>setPaginationData(data))}/>}*/}
        </>
    );
};

export default News;
