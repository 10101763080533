import React, {useState} from 'react';
import styles from './ContactPopup.module.css'
import {SendEmail} from "../../Services/service";
import {UncontrolledButtonDropdown} from "reactstrap";
import { RemoveScroll } from "react-remove-scroll";
// import {
//     GoogleReCaptchaProvider,
//     useGoogleReCaptcha,
//   } from "react-google-recaptcha-v3";

const ContactPopup = (props) => {
    // const { executeRecaptcha } = useGoogleReCaptcha();
    const [emailItem, setEmailItem] = useState({
        fullName: "",
        emailAddress: "",
        companyName: "",
        text: ""
    });

    function sendEmail() {
        return false;

        // if (!executeRecaptcha) {
        //     console.log("Execute recaptcha not yet available");
        //     return;
        //   }
      
        // executeRecaptcha().then((res) => {
        //     if (emailItem && res) {
        //         emailItem.CaptchaToken = res;
        //         SendEmail(emailItem)
        //             .then((response) => {
        //                 props.onClose();
        //             })
        //             .catch((error) => {
   
        //             })
        //     }
        // })

    }

    function handleChangeFieldValue(field, value) {
        setEmailItem({...emailItem, [field]: value})
        if (validators[field] &&!validators[field].test(emailItem[field])) {
            updateValidationResult(field, 'Field Is Invalid')
        }
        else{
            updateValidationResult(field, undefined)
        }
    }

    const validators = {
        fullName: /\S/,
        emailAddress: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        text: /\S/,
    }
    const [validationResult, setValidationResult] = useState({
        fullName: undefined,
        emailAddress: undefined,
        text: undefined
    })
 

    function updateValidationResult(field, value) {
        setValidationResult({...validationResult, [field]: value})

    }
    

    return (
        <>
            <div className={styles.contactPopupBackground} onClick={() => props.onClose()}/>
           <RemoveScroll>
               <div className={styles.contactPopupContainer}>
                   <div className={styles.contactPopupHeader}>
                       <div className={styles.contactTitle}>Send Us An E-Mail</div>
                       <img className={styles.closeContactForm} src={'/assets/images/Close.png'} alt={'Close Icon'}
                            onClick={() => props.onClose()}/>
                   </div>
                   <div className={styles.contactForms}>
                       <div className={styles.contactForm}>
                           <div className={styles.inputTitle}>Full Name</div>
                           <input type={'text'} value={emailItem.fullName}
                                  onChange={(event) => handleChangeFieldValue('fullName', event.target.value)}
                                  placeholder={'Your full name'}/>
                           <div
                               className={styles.errorMessage + ' ' + (validationResult['fullName'] === undefined ? '' : styles.showErrorMessage)}>Full
                               Name is invalid
                           </div>
                       </div>
                       <div className={styles.contactForm}>
                           <div className={styles.inputTitle}>E-mail</div>
                           <input type={'text'} placeholder={'Your E-mail address'}
                                  onChange={(event) => handleChangeFieldValue('emailAddress', event.target.value)}/>
                           <div className={styles.emailInfoWrapper}>
                               <div className={styles.questionMark}>?</div>
                               <div className={styles.emailInfo}><span>We will use your e-mail to contact you</span></div>
                           </div>
                           <div
                               className={styles.errorMessage + ' ' + (validationResult['emailAddress'] === undefined ? '' : styles.showErrorMessage)}>Email
                               is invalid
                           </div>
                       </div>
                       <div className={styles.contactForm}>
                           <div className={styles.inputTitle}>Company | <span
                               className={styles.secondaryText}>Optional</span></div>
                           <input type={'text'} placeholder={'Your company name'}
                                  onChange={(event) => handleChangeFieldValue('companyName', event.target.value)}/>
                       </div>
                       <div className={styles.contactForm + ' ' + styles.textForm}>
                           <div className={styles.inputTitle}>Text</div>
                           <textarea placeholder={'Your text'}
                                     onChange={(event) => handleChangeFieldValue('text', event.target.value)}/>
                           <div
                               className={styles.errorMessage + ' ' + (validationResult['text'] === undefined ? '' : styles.showErrorMessage)}>Text
                               is invalid
                           </div>
                       </div>
                       <button
                           className={styles.sendUsMessage}
                           onClick={() => sendEmail()}>
                           Send Us A Message
                       </button>
                   </div>
               </div>
           </RemoveScroll>
        </>
    );
};

export default ContactPopup;
