import React, {Component, useEffect, useState} from 'react';
import CustomSlider from "../../../Components/Slider/Slider";
import GamesContainer from "../../../Components/GamesContainer/GamesContainer";
import MobileSlider from "../../../Components/Mobile/MobileSlider/MobileSlider";
import MobileGamesContainer from "../../../Components/Mobile/MobileGamesContainer/MobileGamesContainer";
import JetXDescription from "../../../Components/Descriptions/JetXDescription";
import styles from "./JetXPage.module.css";
import { GetBanners, GetCategories } from "../../../Services/service";
import {GetStaticContentBaseUrl, IsMobileBrowser} from "../../../Services/common";
import {Link} from "react-router-dom";
import Popup from '../../../Components/Popup/Popup';

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const JetXPage = () => {
  const [data, setData] = useState(null);
  const [popupActive, setPopupActive] = useState(false);

    useEffect(() => {
        GetBanners("JetX", "Mobile")
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });

    }, [])
  return (
    data && <div>
      {popupActive && <Popup onClose={() => setPopupActive(false)}/>}
      <JetXDescription />
      {
        <a href={data[0].redirectUrl}
            target={data[0].isGameRedirect || data[0].isExternalRedirect ? '_blank' : '_self'}>
            <img className={styles.jetXBanner} src={GetStaticContentBaseUrl() + data[0].filePath} alt={data[0].imageAltAttribute}/>
        </a>
                    
      }
      <div
        className={styles.demoButtonMobile}
        onClick={() => {
          openInNewTab(
            "https://server.ssg-public.com/SmartSoftGamePlay/authorization.aspx?GameName=JetX"
          );
        }}
      ></div>
      <div
          className={styles.howToPlayButton}
          onClick={() => {
            setPopupActive(true);
          }}
          ></div>
      {/* <MobileGamesContainer categoryName={'JetX'}/> */}
      <div className={styles.descriptionContainer}>
        {/* <div className={styles.descriptionColumn}>
          <span className={styles.firstColumnHeader}>
            THE GAME RULES ARE VERY SIMPLE
          </span>
          <span className={styles.firstColumnDescription}>
            <span className={styles.list}>1</span>
            <span>Player sets the amount and places the bet</span>
          </span>
          <span className={styles.firstColumnDescription}>
            <span className={styles.list}>2</span>
            <span>JetX takes off and the winning multiplier starts to rise</span>
          </span>
          <span className={styles.firstColumnDescription}>
            <span className={styles.list}>3</span>
            Players can collect the win anytime
          </span>
          <span className={styles.firstColumnDescription}>
            <span className={styles.list}>4</span>
            The winning = the placed bet X collected multiplier
          </span>
          <span className={styles.firstColumnDescription}>
            <span className={styles.list}>5</span>
            Player loses if JetX explodes before collecting
          </span>
        </div> */}
        <div className={styles.laptop}>
          <div className={styles.descriptionColumn}>
            <span className={styles.secondColumnDescription}>
              <img
                src="assets/images/jetXpage/promos.png"
                className={styles.icon}
              ></img>
              REGULAR NETWORK PROMOS
            </span>
            <span className={styles.secondColumnDescription}>
              <img
                src="assets/images/jetXpage/collect.png"
                className={styles.icon}
              ></img>
              AUTO BET AND COLLECT
            </span>
            <span className={styles.secondColumnDescription}>
              <img
                src="assets/images/jetXpage/doubleBet.png"
                className={styles.icon}
              ></img>
              DOUBLE BET OPTION
            </span>
          </div>
          <div className={styles.descriptionColumnLast}>
            <span className={styles.secondColumnDescription}>
              <img
                src="assets/images/jetXpage/history.png"
                className={styles.icon}
              ></img>
              GAME HISTORY AND STATISTICS
            </span>
            <span className={styles.secondColumnDescription}>
              <img
                src="assets/images/jetXpage/multiplier.png"
                className={styles.icon}
              ></img>
              MULTIPLAYER
            </span>
            <span className={styles.secondColumnDescription}>
              <img
                src="assets/images/jetXpage/interact.png"
                className={styles.icon}
              ></img>
              CHAT FUNCTIONALITY
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JetXPage;
