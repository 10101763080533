import React from 'react';
import CustomSlider from "../../../Components/Slider/Slider";
import GamesContainer from "../../../Components/GamesContainer/GamesContainer";
import XGamesDescription from "../../../Components/Descriptions/XGamesDescription";

const XGames = () => {
    return (
        <>
            <XGamesDescription/>
            <CustomSlider pageName={'XGames'}/>
            <GamesContainer categoryName={'XGames'}/>

        </>
    );
};

export default XGames;
