import React, {useEffect, useState} from 'react';
import styles from './Partners.module.css'
import {GetBanners, GetPartners} from "../../../Services/service";
import {GetStaticContentBaseUrl} from "../../../Services/common";
const Partners = () => {
    const [data, setData] = useState(null);
    useEffect(() => {
        GetPartners()
            .then(response => {
                setData(response.data)
                console.log(response.data);
            })
            .catch(err => {

            });

    }, [])
    return (
        <div className={styles.partnersContainer}>
            <div className={styles.gamingPartners}>
                <h1 className={styles.partnersHeader}>
                    Gaming Partners
                </h1>
                <div className={styles.partnersWrapper}>
                    {
                        data && data.filter(x=>x.partnerType === 'GamingPartner').sort(function (a, b) { return a.priority - b.priority }).map((item,index)=>
                            <a href={item.redirectUrl} key={index} target={'_blank'}>
                                <img className={styles.partnerIcon} src={GetStaticContentBaseUrl() + item.iconPath} alt={item.imageAltAttribute}/>
                            </a>
                        )
                    }
                </div>
            </div>
            <div className={styles.mediaPartners}>
                <h1 className={styles.partnersHeader}>
                    Media Partners
                </h1>
                <div className={styles.partnersWrapper}>
                    {
                        data && data.filter(x=>x.partnerType === 'MediaPartner').sort(function (a, b) { return a.priority - b.priority }).map((item,index)=>
                            <a href={item.redirectUrl} key={index} target={'_blank'}>
                                <img className={styles.partnerIcon} src={GetStaticContentBaseUrl() + item.iconPath} alt={item.title}/>
                            </a>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default Partners;
