import React from 'react';
import {Link} from "react-router-dom";
import styles from "./HeaderLogo.module.css"

const HeaderLogo = () => {
    return (

        <Link to={"/"}>
            <div className={styles.smartSoftLogo}/>
        </Link>
        
    );
};

export default HeaderLogo;
