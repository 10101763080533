import React, {useEffect, useState} from 'react';
import styles from './MobileLeftMenu.module.css'
import {GetCategories, SearchGames} from "../../../Services/service";
import {NavLink} from "react-router-dom";
import {style} from "svgo/lib/svgo/jsAPI";

const MobileLeftMenu = (props) => {
    const [data, setData] = useState(null);
    useEffect(() => {
        GetCategories()
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });

    }, [])
    return (
       <>
           <div className={styles.menuLeft}>

               <div className={styles.leftMenuWrapper}>
                   {
                       Array.isArray(data) && data.length > 0 &&
                       data.map((item, index) =>
                           <NavLink key={index} activeClassName={styles.activeLeftMenuItem} onClick={() => {props.onClose()}} to={''+item.redirectUrl}  className={styles.leftMenuItem +' '+styles[item.categoryName]}>
                               <div className={styles.categoryTitle + ' ' + styles[item.categoryName]}>{item.categoryName}</div>
                               <div className={styles[item.categoryName] +' ' +styles.leftMenuItemIcon}/>
                           </NavLink>
                       )
                   }
               </div>
           </div>
       </>
    );
};

export default MobileLeftMenu;
