import {GetAPIBaseUrl, GetItemFromLocalStorage} from './common';
import axios, {Axios} from 'axios';
import {getAction} from "connected-react-router";

// API CALLS


export function GetBanners(pageName, device) {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetBanners?pageName=${pageName}&&device=${device}`);
}

export function SearchGames(pattern) {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/SearchGames?pattern=${pattern}`)
}

export function SearchVacancies(pattern) {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/SearchVacancies?pattern=${pattern}`)
}

export function GetCategories() {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetCategories`)
}

export function GetPartners() {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetPartners`)
}

export function GetGames(categoryName, isMobile) {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetGames?categoryName=${categoryName}&&isMobile=${isMobile}`)
}
export function GetNewsItems() {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetNewsItems`)
}
export function GetNewsItemById(newsItemId) {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetNewsItemById?newsItemId=${newsItemId}`)
}
export function GetResumeById(resumeId) {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetResumeById?resumeId=${resumeId}`)
}
export function GetMeetUsEvents() {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetMeetUsEvents`)
}
export function GetLicenses() {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetLicenses`)
}
export function GetAllCareerItems() {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetAllCareerItems`)
}
export function SendEmail(emailItem) {
    return axios.post(GetAPIBaseUrl() + `/WebsiteApi/SendEmail`, emailItem)
}
export function ApplyResume(resumeItem) {
    return axios.post(GetAPIBaseUrl() + `/WebsiteApi/ApplyResume`, resumeItem)
}
export function GetKeyWords() {
    return axios.get(GetAPIBaseUrl() + `/WebsiteApi/GetKeyWords`)
}