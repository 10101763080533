import React from 'react';
import CustomSlider from "../../../Components/Slider/Slider";
import GamesContainer from "../../../Components/GamesContainer/GamesContainer";
import KenoDescription from "../../../Components/Descriptions/KenoDescription";

const KenoPage = () => {
    return (
        <>
            <KenoDescription/>
            <CustomSlider  pageName={'Keno'}/>
            <GamesContainer categoryName={'Keno'}/>
        </>
    );
};

export default KenoPage;
