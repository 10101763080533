import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { GetResumeById } from "../../Services/service";
import styles from "./VacancyItem.module.css";
import { ConsoleLog, GetStaticContentBaseUrl } from "../../Services/common";
import Moment from "moment";
import { Helmet } from "react-helmet";
import ApplyForm from "../ApplyForm/ApplyForm";

const VacancyItem = (props) => {
  const [data, setData] = useState(null);
  const [isApplyFormVisile, setIsApplyFormVisile] = useState(false);
  let parameters = new URLSearchParams(window.location.search);
  let resumeId = parameters.get("resumeId");
  useEffect(() => {
      GetResumeById(resumeId)
        .then(response => {
              response.data.description = JSON.parse(response.data.description);
              setData(response.data)
          })
          .catch(err => {

          });

  }, [resumeId])
  
  const url = `${window.location.origin}/vacancyItem?resumeId=${resumeId}`;
  return (
    <>
      {data && (
        <Helmet>
          <meta property="og:title" content={data.vacancyTitle} />
          <meta
            property="og:description"
            content={parse(data.description.vacancyDescription)}
          />
          <meta name="description" content={parse(data.description.vacancyDescription)} />
        </Helmet>
      )}
      <div className={styles.vacancyWrapper}>
        <div className={styles.vacancyItemWrapper}>
          {data && (
            <>
              <section>
                <div className={styles.vacancyLink}>
                  Careers
                </div>
                <div className={styles.publishDate}>
                  {Moment(data.publishDate).format("D MMM, YYYY")}
                </div>
                <div className={styles.headerContainer}>
                  <h1 className={styles.vacancyTitle}>{data.title}</h1>
                </div>
                <p className={styles.vacancyDescription}>
                    {parse(data.description.vacancyDescription)}
                </p>
              </section>
              <section className={styles.sectionOne}>
                <div className={styles.sectionOneColumn}>
                  <div className={styles.sectionHeaders}>
                    <div className={styles.horizontalLine}></div>
                    <span>EMPLOYMENT TERM</span>
                  </div>
                  <div className={styles.sectionOneDescriptions}>
                    {data.description.employmentTerm}
                  </div>
                </div>
                <div className={styles.sectionOneColumn}>
                  <div className={styles.sectionHeaders}>
                    <div className={styles.horizontalLine}></div>
                    <span>JOB TYPE</span>
                  </div>
                  <div className={styles.sectionOneDescriptions}>
                    {data.jobType}
                  </div>
                </div>
                <div className={styles.sectionOneColumn}>
                  <div className={styles.sectionHeaders}>
                    <div className={styles.horizontalLine}></div>
                    <span>DEADLINE</span>
                  </div>
                  <span className={styles.sectionOneDescriptions}>
                    {Moment(data.expireDate).format("D")}{" "}
                  </span>
                  <span>
                    {Moment(data.expireDate).format("MMM, yyyy")}
                  </span>
                </div>
              </section>
              <section className={styles.sectionTwo}>
                <div className={styles.sectionHeaders}>
                  <div className={styles.horizontalLine}></div>
                  <span>RESPONSIBILITIES</span>
                </div>
                <p className={styles.vacancyDescription}>
                  {parse(data.description.vacancyResponsibilities)}
                </p>
              </section>
              <section className={styles.sectionThree}>
                <div className={styles.sectionHeaders}>
                  <div className={styles.horizontalLine}></div>
                  <span>REQUIREMENTS</span>
                </div>
                <p className={styles.vacancyDescription}>
                  {parse(data.description.vacancyRequirements)}
                </p>
              </section>
              <section className={styles.sectionFour}>
                <div className={styles.sectionHeaders}>
                  <div className={styles.horizontalLine}></div>
                  <span>SKILLS</span>
                </div>
                <p className={styles.vacancyDescription}>
                  {parse(data.description.vacancySkills)}
                </p>
              </section>
              <section className={styles.sectionFive}>
                <div className={styles.sectionHeaders}>
                  <div className={styles.horizontalLine}></div>
                  <span>WORKING CONDITIONS</span>
                </div>
                <p className={styles.vacancyDescription}>
                  {parse(data.description.vacancyWorkingConditions)}
                </p>
              </section>
              <section className={styles.sectionFive}>
                <p
                  className={styles.vacancyDescription}
                  style={{ fontStyle: "italic" }}
                >
                  We would like to inform you that your personal data will be
                  processed by the "SMARTSOFT GAMING" in order to make a
                  decision on employment and, also to determine the eligibility
                  of the candidate for the current/future vacancy requirements
                  and will be stored for a maximum of 5 years.
                </p>
                <div className={styles.sectionHeaders}>
                  <p style={{ fontStyle: "italic" }}>
                    Thank you for your interest and we look forward to meeting
                    you!
                  </p>
                </div>
              </section>
              <button
                className={styles.applyButton}
                onClick={() => setIsApplyFormVisile(true)}
              >
                APPLY NOW
              </button>
            </>
          )}
          <Link className={styles.backToVacancy} to={"/career"}>
            {" "}
            &lt; Back To Careers
          </Link>
        </div>
        {/* {isApplyFormVisile && <ApplyForm careerItemId={resumeId}></ApplyForm>} */}
      </div>
    </>
  );
};

export default VacancyItem;
