import React, {useEffect, useState} from 'react';
import styles from './MeetUs.module.css'
import PathNavLink from "../../../Components/PathNavLink/PathNavLink";
import {GetStaticContentBaseUrl} from "../../../Services/common";
import Moment from "moment";
import {Link} from "react-router-dom";
import {GetMeetUsEvents, GetNewsItems} from "../../../Services/service";

const MeetUs = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        GetMeetUsEvents()
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });

    }, [])

    return (
        <div className={styles.meetWrapperContainer}>
            <div className={styles.topEvents}>upcomming events</div>
            <div className={styles.meetContainer}>
                <div className={styles.topMeetEvents}>
                    {  data && data.filter(item=>item.priority === 0).map((item, index) =>
                        <a href={item.redirectUrl} target={"_blank"} key={index} className={styles.meetItemWrapper}>
                            <img className={styles.meetImage} src={GetStaticContentBaseUrl() + item.filePath}
                                 alt={item.imageAltAttribute}/>
                            <div className={styles.meetHeadWrapper}>

                                <div className={styles.meetHeadWrapperInfo}>
                                    <div className={styles.publishDate}>
                                        <span>{Moment(item.publishDate).format("D")} </span><span
                                        className={styles.monthName}>{Moment(item.publishDate).format('MMM, yyyy')}</span></div>
                                    <div className={styles.meetTitle}>{item.meetTitle}</div>
                                    <div className={styles.meetDescription}>{item.meetDescription}</div>

                                </div>
                            </div>
                        </a>
                    )
                    }
                </div>
                {
                    data && data.filter(item=>item.priority !== 0).map((item, index) =>
                        <a href={item.redirectUrl} target={"_blank"} key={index} className={styles.meetItemWrapper}>
                            <img className={styles.meetImage} src={GetStaticContentBaseUrl() + item.filePath}
                                 alt={item.imageAltAttribute}/>
                            <div className={styles.meetHeadWrapper}>

                                <div className={styles.meetHeadWrapperInfo}>
                                    <div className={styles.publishDate}>
                                        <span>{Moment(item.publishDate).format("D")} </span><span
                                        className={styles.monthName}>{Moment(item.publishDate).format('MMM, yyyy')}</span></div>
                                    <div className={styles.meetTitle}>{item.meetTitle}</div>
                                    <div className={styles.meetDescription}>{item.meetDescription}</div>

                                </div>
                            </div>
                        </a>
                    )
                }
            </div>

        </div>
    );
};

export default MeetUs;
