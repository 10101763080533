import React, {useEffect, useState} from 'react';
import styles from './MobileGamesContainer.module.css'
import {GetGames} from "../../../Services/service";
import {GetGameRedirectUrl, GetStaticContentBaseUrl, IsMobileBrowser} from "../../../Services/common";

const GamesContainer = (props) => {
    const [data, setData] = useState(null);
    const isMobile = IsMobileBrowser();
    useEffect(() => {
        GetGames(props.categoryName, isMobile)
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });

    }, [])

    return (
        <div className={styles.gamesContainer + ' ' + styles[props.categoryName]}>
            <div className={styles.gamesWrapper}>
                {
                    data && data.map((item, index) =>
                        <a key={index} className={styles.gameItem} href={GetGameRedirectUrl(item.gameName)} target={'_blank'}>
                            <img src={GetStaticContentBaseUrl() +'' +item.mobileImagePath} alt={item.imageAltAttribute}/>
                            <div className={styles.gameTitle}>{item.displayName}</div>
                            
                        </a>
                    )
                }
            </div>
        </div>
    );
};

export default GamesContainer;
