import styles from "./Licenses.module.css";
import React, { useEffect, useState } from "react";
import { GetStaticContentBaseUrl } from "../../../Services/common";
import { GetLicenses } from "../../../Services/service";
import parse from "html-react-parser";

const Licenses = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    GetLicenses()
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className={styles.licensesContainer}>
      <div className={styles.licensesWrapper}>
        <div className={styles.licensesItems}>
          <h1 className={styles.licensesHeader}>Licenses</h1>
          {data &&
            data
              .filter((a) => {
                return (
                  a.licenseTitle.charAt(0) == "L" ||
                  a.licenseTitle.charAt(0) == "l"
                );
              })
              .map((item, index) => (
                <a key={index} href={item.imageAltAttribute} target="_blank">
                  <div className={styles.licenseItem}>
                    <img
                      className={styles.licenseIcon}
                      src={GetStaticContentBaseUrl() + item.imagePath}
                      alt={item.licenseTitle.slice(2)}
                    />
                    <div className={styles.licenseItemLeft}>
                      <div className={styles.licenseCountry}>
                        {item.licenseTitle.slice(2)}
                      </div>
                      <div className={styles.licenseItemInfo}>
                        {parse(item.licenseDescription)}
                      </div>
                    </div>
                  </div>
                </a>
              ))}
          <h1 className={styles.licensesHeader}>Certifications</h1>
          {data &&
            data
              .filter((a) => {
                return (
                  a.licenseTitle.charAt(0) == "C" ||
                  a.licenseTitle.charAt(0) == "c"
                );
              })
              .map((item, index) => (
                
                  <div className={styles.licenseItem}>
                    <img
                      className={styles.licenseIcon}
                      src={GetStaticContentBaseUrl() + item.imagePath}
                      alt={item.licenseTitle.slice(2)}
                    />
                    <div className={styles.licenseItemLeft}>
                      <div className={styles.licenseCountry}>
                        {item.licenseTitle.slice(2)}
                      </div>
                      <div className={styles.licenseItemInfo}>
                        {parse(item.licenseDescription)}
                      </div>
                    </div>
                  </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Licenses;
