import React, {useEffect, useState} from 'react';
import {Carousel} from "react-responsive-carousel";
import {GetStaticContentBaseUrl} from "../../../Services/common";
import {GetBanners} from "../../../Services/service";

const MobileSlider = (props) => {
    const [data, setData] = useState(null);
    useEffect(() => {
        GetBanners(props.pageName, "Mobile")
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });

    }, [])
    return (

        data && <>
            <Carousel axis={'vertical'} showArrows={false} showStatus={false} showIndicators={true} infiniteLoop={true}
                      showThumbs={false} useKeyboardArrows={true}
                      autoPlay={true} stopOnHover={true} swipeable={true} dynamicHeight={true}
                      preventMovementUntilSwipeScrollTolerance={false} selectedItem={0}
                      interval={5000} transitionTime={500} swipeScrollTolerance={5}
                      emulateTouch={true} autoFocus={false}>

                {
                    data.map((item, index) =>
                        <a key={index} className={'displaySlideBlock'} href={item.redirectUrl}
                           target={item.isGameRedirect || item.isExternalRedirect ? '_blank' : '_self'}>
                            <img src={GetStaticContentBaseUrl() + item.filePath} alt={item.imageAltAttribute}/>
                        </a>
                    )
                }

            </Carousel>
        </>
    );
};

export default MobileSlider;
