import React from 'react';
import CustomSlider from "../../../Components/Slider/Slider";
import GamesContainer from "../../../Components/GamesContainer/GamesContainer";
import SlotsDescription from "../../../Components/Descriptions/SlotsDescription";

const SlotsPage = () => {
    return (
        <>
            <SlotsDescription/>
            <CustomSlider pageName={'Slots'}/>
            <GamesContainer categoryName={'Slots'}/>
        </>
    );
};

export default SlotsPage;
