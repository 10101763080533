import React, { Component, useState } from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import PartnersSlider from "../../../../Components/PartnersSlider/PartnersSlider";
import TermsAndConditions from "../../../../Components/TermsAndConditions/TermsAndConditions";
import MailchimpSubscribe from "react-mailchimp-subscribe";
const url =
  "https://smartsoftgaming.us4.list-manage.com/subscribe/post?u=83acb9a18e5cd66a768f085d8&id=25964c43c5";

const Footer = () => {
  //   const SimpleForm = ({onValidated}) => {
  //   let email;
  //   const submit = () =>
  //     email &&
  //     email.value.indexOf("@") > -1 &&
  //     onValidated({
  //       EMAIL: email.value,
  //     })

  //   return (
  //     <div className={styles.subscribeInputContainer}>
  //       <input
  //         className={styles.subscribeInput}
  //         ref={(node) => (email = node)}
  //         type="email"
  //         placeholder={"your email"}
  //       />
  //       <a
  //         onClick={submit}
  //         target={"_blank"}
  //         className={styles.subscribeButton}
  //       >
  //         Subscribe
  //       </a>
  //     </div>
  //   );
  // };
  const [showTerms, setShowTerms] = useState(false);
  return (
    <>
      {/* <div className={styles.subscribeUsContainer}>
        <div className={styles.subscribeWrapper}>
          <div className={styles.subscribeUs}>
            <div className={styles.subscribeTitle}>
              Subscribe to our
              <br />
              <span>NewsLetter</span>
            </div>
            <div className={styles.subscribeDescription}>
              Sign up to receive email updates on our new product announcements,
              releases and latest news
            </div>
            <div>
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <div>
                    <SimpleForm
                      onValidated={formData => subscribe(formData)}
                    />
                    {status === "sending" && (
                      <div style={{ color: "rgb(20, 146, 227)" }}>sending...</div>
                    )}
                    {status === "error" && (
                      <div
                        style={{ color: "rgb(234, 1, 58)" }}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    )}
                    {status === "success" && (
                      <div style={{ color: "green" }}>Subscribed !</div>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <img
          className={styles.mummyImage}
          src="assets/images/Mummy.png"
          alt="SmartSoft Mummy"
        />
      </div> */}
      {/* <PartnersSlider /> */}
      <div className={styles.footerContainer}>
        <div className={styles.certificatesAndContactsWrapper}>
          <div className={styles.certificates}>
            <div>18+</div>
            <a />
            <a href={"https://authorisation.mga.org.mt/verification.aspx?lang=EN&company=cddfc9c3-5fc3-4915-9273-3558240cbf6e&details=1/"} target="_blank"/>
            <a  href={"http://onjn.gov.ro/lista-licentiati-clasa-a-ii-a//"} target="_blank"/>
            <a />
            <a href={"https://www.rgf.org.mt/"} target="_blank" />
            <a />
            
            
          </div>
          <div className={styles.contactContainer}>
            <a className={styles.phoneNumber} href={"tel:+995 598 77 27 77"}>
              +995 598 77 27 77
            </a>
            {/* <a
              className={styles.map}
              target="_blank"
              href="https://www.google.com/maps/place/%E1%83%A8%E1%83%9E%E1%83%A1+%E1%83%A1%E1%83%9B%E1%83%90%E1%83%A0%E1%83%A2%E1%83%A1%E1%83%9D%E1%83%A4%E1%83%A2%E1%83%98/41.725411,44.7299525,16z/data=!4m19!1m13!4m12!1m4!2m2!1d44.7381504!2d41.7267712!4e1!1m6!1m2!1s0x404473125e727f35:0x3d2502cef56a12b2!2ssmartsoft!2m2!1d44.7304326!2d41.7245444!3m4!1s0x404473125e727f35:0x3d2502cef56a12b2!8m2!3d41.7245444!4d44.7304326"
            >
              Vaja Pshavelas #71, Tbilisi, Georgia
            </a> */}

            <a className={styles.mail} href="mailto:sales@smartsoft.ge">
              info@smartsoft.ge
            </a>
          </div>
        </div>
        <div className={styles.footerBottomLine}>
          <div
            onClick={() => setShowTerms(true)}
            className={styles.termsAndConditions}
          >
            Terms and Conditions
          </div>
          <div className={styles.footerText}>
            Smartsoft Limited, with company registration number C 99015, is a licensed and regulated B2B entity by Malta Gaming Authority under license number MGA/B2B/925/2021, having its registered address at: Level 3 (Suite 2746), Tower Business Centre Tower Street, Swatar, BIRKIRKARA, Malta.
          </div>
          <div className={styles.allRights}>
            &copy; 2015-2023 All rights reserved
          </div>
        </div>
      </div>
      {showTerms && <TermsAndConditions onClose={() => setShowTerms(false)} />}
    </>
  );
};

export default Footer;
