import React from 'react';
import {GetGameRedirectUrl} from "../../Services/common";
import styles from './GameDemo.module.css'
import {Redirect, Route} from "react-router";
import {useParams} from "react-router";

const GameDemo = (props) => {
    const params = useParams();
    // console.log(params)
    return (
        <Route path='*' component={() => {
            window.location.href = GetGameRedirectUrl(params.gameName);
            return null;
        }}/>
    );
};

export default GameDemo;
