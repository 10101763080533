import React from 'react';
import CustomSlider from "../../../Components/Slider/Slider";
import GamesContainer from "../../../Components/GamesContainer/GamesContainer";
import MobileSlider from "../../../Components/Mobile/MobileSlider/MobileSlider";
import MobileGamesContainer from "../../../Components/Mobile/MobileGamesContainer/MobileGamesContainer";
import RouletteDescription from "../../../Components/Descriptions/RouletteDescription";

const CasinoPage = () => {
    return (
        <>
            <RouletteDescription/>
            <MobileSlider pageName={'Casino'}/>
            <MobileGamesContainer categoryName={'Casino'}/>

        </>
    );
};

export default CasinoPage;
