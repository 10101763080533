import React, {useEffect, useState} from 'react';
import styles from './Search.module.css'
import {SearchGames} from "../../Services/service";
import {GetGameRedirectUrl} from "../../Services/common";

const Search = () => {
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [data, setData] = useState(null);
    const [searchFinished, setSearchFinished] = useState(false)
    const [pattern, setPattern] = useState("");
    useEffect(() => {
        if (pattern && /\S/.test(pattern)) {
            setSearchFinished(false)
            setData([])
            SearchGames(pattern)
                .then(response => {
                    setData(response.data)
                    setSearchFinished(true)
                })
                .catch(err => {

                });
        }
    }, [pattern])

    function handleCloseSearch() {
        setData(null)
        setSearchFinished(false)
        setShowSearchBar(false)
    }

    return (
        <div className={styles.searchContainer}>
            {!showSearchBar && <div onClick={() => setShowSearchBar(true)} className={styles.searchIcon}/>}
            {showSearchBar &&
                <div className={styles.searchWrapper}>
                    <div className={styles.searchIconInside}/>
                    <input autoFocus={false} onChange={(event) => setPattern(event.currentTarget.value)}
                           className={styles.searchInput} type={"text"} placeholder={"Search Game"}/>
                    <div onClick={() => handleCloseSearch()} className={styles.clearSearchIcon}/>
                </div>
            }
            {Array.isArray(data) && data.length > 0 && pattern && searchFinished && /\S/.test(pattern) &&
                <div className={styles.searchResultWrapper}>

                    {
                        data.map((item, index) =>

                            <a key={index} className={styles.searchResultItem} target={'_blank'} href={GetGameRedirectUrl(item.gameName)}>
                                <div className={styles.subMenuItem}
                                >{item.displayName}</div>
                                <span className={styles.category}>{item.gameType}</span>
                            </a>
                        )
                    }


                </div>
            }
            {!Array.isArray(data) || (!(data.length > 0) && pattern && searchFinished) &&
                <div className={styles.searchResultWrapper}>
                    <div className={styles.notFound}>
                        There are no results to match your search
                    </div>
                </div>
            }
        </div>
    );
};

export default Search;
