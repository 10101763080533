import React from 'react';
import {Helmet} from "react-helmet";

const JetXDescription = () => {
    return (
        <Helmet>
            <meta name="description" content="JetX is an arcade type crash game created by SmartSoft Gaming. Play JetX, place a bet and hop off the exploding rocket before it's too late!"/>
        </Helmet>
    );
};

export default JetXDescription;
