import React, { useEffect, useState } from "react";
import styles from "./Career.module.css";
import { GetAllCareerItems } from "../../../Services/service";
import Moment from "moment";
import parse from 'html-react-parser';
import {Link} from "react-router-dom";


const Career = () => {
    const [data, setData] = useState(null);
      useEffect(() => {
        GetAllCareerItems()
          .then((response) => {
            setData(response.data);
          })
          .catch((err) => {});
      },[]);
  

  const [q, setQ] = useState("");
  const [searchTerm] = useState(["title"]);

  function search(items) {
    return items.filter((item) => {
      return searchTerm.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
    }

    // data && data.sort(function (a, b) { return b.priority - a.priority }).map((item, index) => (console.log(item.vacancyTitle)));

  return (
    <>
      <div className={styles.careerContainer}>
        <div className={styles.ongoingVacanciesContainer}>
          <div className={styles.ongoingVacanciesWrapper}>
            <div className={styles.ongoingVacanciesHeader}>
              <div className={styles.searchContainer}>
                <div className={styles.searchWrapper}>
                  <div className={styles.searchIcon} />
                  <input
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                    autoFocus={false}
                    className={styles.searchInput}
                    type={"text"}
                    placeholder={"Search Vacancy"}
                  />
                                  <div className={styles.clearSearchIcon}
                                      onClick={(e) => setQ('') }/>
                </div>
              </div>
            </div>
            <div className={styles.vacancyContainer}>
              {data && search(data).sort(function (a, b) { return b.priority - a.priority }).map((item, index) => (
                <div key={index} id={index} className={styles.vacancyItem}>
                    <div className={styles.vacancyItemHeader}>
                        <div className={styles.vacancyTitle}> {item.title} </div>
                            <div className={styles.vacancyDeadline}>
                                <div className={styles.horizontalLine}></div>
                                <div className={styles.clockIcon}></div>
                                <span className={styles.vacancyDeadlineText}> DEADLINE: </span>
                                <span>{Moment(item.expireDate).format("D")} </span>
                                <span className={styles.monthName}>{Moment(item.expireDate).format('MMM, yyyy')}</span>
                            </div>
                        </div>
                        <h2 className={styles.vacancyDescription}>{parse(JSON.parse(item.description).vacancyDescription.slice(0,250))} ... <Link to={`/vacancyItem?resumeId=${item.careerItemId}`} className={styles.seeAll}>SEE DETAILS</Link></h2>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
