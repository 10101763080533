import React from 'react';
import CustomSlider from "../../../Components/Slider/Slider";
import GamesContainer from "../../../Components/GamesContainer/GamesContainer";
import MobileSlider from "../../../Components/Mobile/MobileSlider/MobileSlider";
import MobileGamesContainer from "../../../Components/Mobile/MobileGamesContainer/MobileGamesContainer";
import KenoDescription from "../../../Components/Descriptions/KenoDescription";

const KenoPage = () => {
    return (
        <>
            <KenoDescription/>
            <MobileSlider  pageName={'Keno'}/>
            <MobileGamesContainer categoryName={'Keno'}/>
        </>
    );
};

export default KenoPage;
