import * as React from 'react';
import styles from './WebAlert.module.css'
// import Alert from '@mui/material/Alert';
// import  GlobalStyles from '@mui/styled-engine/legacy/GlobalStyles/GlobalStyles';

// const useStyles = GlobalStyles((theme) => ({
//     root: {
//         width: '100%',
//         '& > * + *': {
//             marginTop: theme.spacing(2),
//         },
//     },
//     color: {
//         backgroundColor: "red"
//     },
//     fixed: {
//         position: "fixed",
//         top: "10px",
//         left: 0,
//         right: 0,
//         marginLeft: "auto",
//         marginRight: "auto",
//         zIndex: "9999",
//         width: "100%",
//         display: "flex",
//         justifyContent: "center"

//     }
// }));

const WebAlert = (props) => {
    // let classes = useStyles();
    // const [isAlertVisible, setIsAlertVisible] = useState(true);
    
    return (
        <div>
            {
                // props.alertType === 0 && isAlertVisible &&
                // <Alert severity="success">This is a success alert — check it out!</Alert>
                // <Alert variant="filled" onClose={() => setIsAlertVisible(false)} severity="success">
                //     {/* {props.message ? props.message : "success"} */}
                // </Alert>
                <h1 className={styles.alert}>Link Copied To Clipboard</h1>
            }
        </div>
    );
};

export default WebAlert;
