import React from 'react';
import {Helmet} from "react-helmet";

const SlotsDescription = () => {
    return (
        <Helmet>
            <meta name="description" content="Play Free SmartSoft Gaming Online Slots and enjoy classic and extremely good graphics all in one."/>
        </Helmet>
    );
};

export default SlotsDescription;
