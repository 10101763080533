import React from 'react';
import CustomSlider from "../../../Components/Slider/Slider";
import GamesContainer from "../../../Components/GamesContainer/GamesContainer";
import RouletteDescription from "../../../Components/Descriptions/RouletteDescription";

const CasinoPage = () => {
    return (
        <>
            <RouletteDescription/>
            <CustomSlider pageName={'Casino'}/>
            <GamesContainer categoryName={'Casino'}/>

        </>
    );
};

export default CasinoPage;
