import React from 'react';
import {Helmet} from "react-helmet";

const KenoDescription = () => {
    return (
            <Helmet>
                <meta name="description" content="Play our Keno games online and enjoy the timeless style and gameplay of Classic Keno, Russian Keno and Vip Keno games."/>
            </Helmet>
    );
};

export default KenoDescription;
