import React, {Component, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import './Slider.css'
import {GetBanners, GetCategories} from "../../Services/service";
import {GetStaticContentBaseUrl, IsMobileBrowser} from "../../Services/common";
import {Link} from "react-router-dom";

const CustomSlider = (props) => {
    const [data, setData] = useState(null);
    useEffect(() => {
        GetBanners(props.pageName, "Desktop")
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });

    }, [])
    
    return (
        data && <>
            <Carousel axis={'vertical'} showArrows={false} showStatus={false} showIndicators={true} infiniteLoop={true}
                      showThumbs={false} useKeyboardArrows={true}
                      autoPlay={true} stopOnHover={true} swipeable={true} dynamicHeight={true}
                      preventMovementUntilSwipeScrollTolerance={false}
                      interval={5000} transitionTime={500} selectedItem={0} swipeScrollTolerance={5}
                      emulateTouch={true} autoFocus={false}>

                {
                    data.map((item, index) =>
                        <>
                            {
                                item.isExternalRedirect ?
                                    <a key={index} className={'displaySlideBlock'} href={item.redirectUrl}
                                       target={item.isGameRedirect || item.isExternalRedirect ? '_blank' : '_self'}>
                                        <img src={GetStaticContentBaseUrl() + item.filePath}
                                             alt={item.imageAltAttribute}/>
                                    </a> : <Link key={index} className={'displaySlideBlock'}
                                                 to={item.redirectUrl}>
                                        <img src={GetStaticContentBaseUrl() + item.filePath} alt={item.imageAltAttribute}/>
                                    </Link>

                            }


                        </>
                    )
                }

            </Carousel>
        </>
    );
};

export default CustomSlider;
