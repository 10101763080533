import React from 'react';
import {NavLink} from "react-router-dom";

const PathNavLink = (props) => {
    return (
        <>
            <NavLink className={props.className} isActive={(match, location) => location.pathname + location.search ===  props.to} {...props} />
        </>
    );
};

export default PathNavLink;
