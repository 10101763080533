import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { GetNewsItemById } from "../../../Services/service";
import styles from "./MobileNewsItem.module.css";
import { GetStaticContentBaseUrl } from "../../../Services/common";
import Moment from "moment";
import { Helmet } from "react-helmet";
import WebAlert from "../../Alerts/WebAlert";

const MobileNewsItem = (props) => {
  /*alert*/
  const [alertData, setAlertData] = useState({
    alertType: null,
    alertMessage: null,
  });
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleShowAlert = (alertData) => {
    setIsAlertVisible(true);
    setAlertData(alertData);
    setTimeout(function () {
      setIsAlertVisible(false);
    }, 1000);
  };
  /*alert end*/
  const [data, setData] = useState(null);
  let parameters = new URLSearchParams(window.location.search);
  let newsItemId = parameters.get("newsId");
  useEffect(() => {
    GetNewsItemById(newsItemId)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {});
  }, [newsItemId]);
  const url = `${window.location.origin}/newsItem?newsId=${newsItemId}`;
  function copyLink() {
    navigator.clipboard.writeText(`${url}`);
    handleShowAlert({
      alertType: 0,
      alertMessage: "Copied",
    });
  }
  return (
    <>
      {data && (
        <Helmet>
          <meta property="og:title" content={data.newsTitle} />
          <meta
            property="og:image"
            content={GetStaticContentBaseUrl() + data.filePath}
          />
          <meta
            property="og:description"
            content={parse(data.newsDescription)}
          />
          <meta name="description" content={parse(data.newsDescription)} />
        </Helmet>
      )}
      <div className={styles.newsItemWrapper}>
        {data && (
          <>
            <img
              className={styles.newsImage}
              src={GetStaticContentBaseUrl() + data.filePath}
              alt={data.imageAltAttribute}
            />
            <div className={styles.publishDate}>
              {Moment(data.publishDate).format("D MMM, YYYY")}
              {isAlertVisible && (
                <WebAlert
                  alertType={alertData.alertType}
                  message={alertData.alertMessage}
                />
              )}
            </div>
            <div className={styles.headerContainer}>
              <h1 className={styles.newsTitle}>{data.newsTitle}</h1>
              <img
                onClick={() => copyLink()}
                className={styles.shareImage}
                src="/assets/images/Share.png"
                alt="Share Icon"
              />
            </div>

            <p className={styles.newsDescription}>
              {parse(data.newsDescription)}
            </p>
          </>
        )}
        <Link className={styles.backToNews} to={"/news?category=All"}>
          {" "}
          &lt; Back to News
        </Link>
      </div>
    </>
  );
};

export default MobileNewsItem;
