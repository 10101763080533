import React, {useEffect, useState} from 'react';
import styles from './GamesContainer.module.css'
import {GetGames} from "../../Services/service";
import {GetGameRedirectUrl, GetStaticContentBaseUrl, IsMobileBrowser} from "../../Services/common";

const GamesContainer = (props) => {
    const [data, setData] = useState(null);
    const isMobile = IsMobileBrowser();
    useEffect(() => {
        GetGames(props.categoryName, isMobile)
            .then(response => {
                setData(response.data)
            })
            .catch(err => {

            });

    }, [])
    return (
        <div className={styles.gamesContainer + ' ' + styles[props.categoryName]}>
            <div className={styles.gamesWrapper}>
                {
                    data && data.map((item, index) =>
                        <div key={index} className={styles.gameItem}>
                            <img src={GetStaticContentBaseUrl() +'' +item.imagePath} alt={item.imageAltAttribute}/>
                            <a href={GetGameRedirectUrl(item.gameName)} className={styles.tryDemo} target={'_blank'}>
                                <div className={styles.tryDemoTitle}>Try Demo</div>
                            </a>
                            <div className={styles.gameTitle}>{item.displayName}</div>
                            
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default GamesContainer;
