import React, { useEffect, useState } from "react";
import styles from "./Licenses.module.css";
import { GetStaticContentBaseUrl } from "../../../Services/common";
import { GetLicenses } from "../../../Services/service";
import parse from "html-react-parser";

const Licenses = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    GetLicenses()
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {});
  }, []);

  //   useEffect(() => {
  //     setData([
  //       {
  //         licenseId: 5,
  //         licenseCountry: "Georgia",
  //         licenseItemInfo:
  //           "Ministry of Finance of Georgia",
  //         filePath: "/assets/images/certificates/GeorgiaFinance.png",
  //         priority: 0,
  //         imageAltAttribute: null,
  //       },
  //       {
  //         licenseId: 4,
  //         licenseCountry: "Georgia",
  //         licenseItemInfo:
  //           "Ministry of Finance of Georgia",
  //         filePath: "/assets/images/certificates/GeorgiaFinance.png",
  //         priority: 0,
  //         imageAltAttribute: null,
  //       },
  //       {
  //         licenseId: 3,
  //         licenseCountry: "Georgia",
  //         licenseItemInfo:
  //           "Ministry of Finance of Georgia",
  //         filePath: "/assets/images/certificates/GeorgiaFinance.png",
  //         priority: 0,
  //         imageAltAttribute: null,
  //       },
  //       {
  //         licenseId: 2,
  //         licenseCountry: "Georgia",
  //         licenseItemInfo:
  //           "Ministry of Finance of Georgia",
  //         filePath: "/assets/images/certificates/GeorgiaFinance.png",
  //         priority: 0,
  //         imageAltAttribute: null,
  //       },
  //     ]);
  //   }, []);
  return (
    <div className={styles.licensesContainer}>
      <div className={styles.licensesWrapper}>
        <h2 className={styles.licensesDescription}>
          Currently, acts as a certified software provider to licensed operators
          in Georgia, Romania, Belarus, Colombia, Italy, Croatia.
        </h2>
        <div className={styles.licensesItems}>
          <h1 className={styles.licensesHeader}>Licenses</h1>
          {data &&
            data
              .filter((a) => {
                return (
                  a.licenseTitle.charAt(0) == "L" ||
                  a.licenseTitle.charAt(0) == "l"
                );
              })
              .map((item, index) => (
                <a key={index} href={item.imageAltAttribute} target="_blank">
                  <div className={styles.licenseItem}>
                    <div className={styles.licenseItemLeft}>
                      <div className={styles.licenseCountry}>
                        {item.licenseTitle.slice(2)}
                      </div>
                      <div className={styles.licenseItemInfo}>
                        {parse(item.licenseDescription)}
                      </div>
                    </div>
                    <img
                      className={styles.licenseIcon}
                      src={GetStaticContentBaseUrl() + item.imagePath}
                      alt={item.licenseTitle.slice(2)}
                    />
                  </div>
                </a>
              ))}
          <h1 className={styles.licensesHeader}>Certifications</h1>
          {data &&
            data
              .filter((a) => {
                return (
                  a.licenseTitle.charAt(0) == "C" ||
                  a.licenseTitle.charAt(0) == "c"
                );
              })
              .map((item, index) => (
                <div key={index} className={styles.licenseItem}>
                  <div className={styles.licenseItemLeft}>
                    <div className={styles.licenseCountry}>
                      {item.licenseTitle.slice(2)}
                    </div>
                    <div className={styles.licenseItemInfo}>
                      {parse(item.licenseDescription)}
                    </div>
                  </div>
                  <img
                    className={styles.licenseIcon}
                    src={GetStaticContentBaseUrl() + item.imagePath}
                    alt={item.licenseTitle.slice(2)}
                  />
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Licenses;
