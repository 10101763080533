import React from 'react';
import {Redirect, Route, Switch} from "react-router";
import HomePage from "../../HomePage/HomePage";
import JetXPage from "../../JetXPage/JetXPage";
import SlotsPage from "../../SlotsPage/SlotsPage";
import KenoPage from "../../KenoPage/KenoPage";
import XGames from "../../XGames/XGames";
import CasinoPage from "../../Casino/CasinoPage";
import AboutUs from "../../AboutUs/AboutUs";
import Licenses from "../../Licenses/Licenses";
import Partners from "../../Partners/Partners";
import News from "../../News/News";
import Applied from "../../Applied/Applied";
import Career from "../../Career/Career";
import MeetUs from "../../MeetUs/MeetUs";
import MobileNewsItem from "../../../../Components/Mobile/MobileNewsItem/MobileNewsItem";
import MobileVacancyItem from "../../../../Components/Mobile/MobileVacancyItem/MobileVacancyItem";
// import MobileApplyForm from "../../../../Components/Mobile/MobileApplyForm/MobileApplyForm";


const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" render={() => (<HomePage pageName={"/HomePage"}/>)}/>
            <Route path="/home" render={() => (<HomePage pageName={"/HomePage"}/>)}/>
            <Route path="/JetX" render={() => (<JetXPage pageName={"/JetXPage"}/>)}/>
            <Route path="/Slots" render={() => (<SlotsPage pageName={"/SlotsPage"}/>)}/>
            <Route path="/Keno" render={() => (<KenoPage pageName={"/KenoPage"}/>)}/>
            <Route path="/XGames" render={() => (<XGames pageName={"/XGames"}/>)}/>
            <Route path="/Casino" render={() => (<CasinoPage pageName={"/CasinoPage"}/>)}/>
            <Route path="/about-us" render={() => (<AboutUs pageName={"/aboutUs"}/>)}/>
            <Route path="/licenses" render={() => (<Licenses pageName={"/licenses"}/>)}/>
            <Route path="/partners" render={() => (<Partners pageName={"/partners"}/>)}/>
            <Route path="/news" render={() => (<News pageName={"/news"}/>)}/>
            <Route path="/newsItem" render={() => (<MobileNewsItem pageName={"/newsItem"} />)} />
            <Route path="/vacancyItem" render={() => (<MobileVacancyItem pageName={"/vacancyItem"}/>)}/>
            {/* <Route path="/mobileApplyForm" render={() => (<MobileApplyForm pageName={"/mobileApplyForm"}/>)}/> */}
            <Route path="/career" render={() => (<Career pageName={"/career"} />)} />
            <Route path="/applied" render={() => (<Applied pageName={"/applied"}/>)}/>
            <Route path="/meet-us" render={() => (<MeetUs pageName={"/meet-us"}/>)}/>
        </Switch>
    );
};

export default Routes;
