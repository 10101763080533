import React from 'react';
import { useStore } from 'react-redux';
import styles from './Applied.module.css'
import { useEffect } from 'react';

const Applied = () => {
    return (
        <>
            <div className={styles.thankYouContainer}>
                <div className={styles.thankYouInnerContainer}>
                    <div className={styles.CheckIcon}></div>
                    <h1 className={styles.thankYouHeader}>
                        Thank You
                    </h1>
                    <h2 className={styles.thankYouDescription}>
                        Information is submited and secured with us . our team will contact you by email or phone
                    </h2>
                </div>
                <img className={styles.thankYouImage} src="/assets/images/AppliedBackground.png" alt="SmartSoft"/>
            </div>
        </>
    );
};

export default Applied;
