import React from 'react';
import styles from "./AgeCheck.module.css";
import {RemoveScroll} from "react-remove-scroll";

const AgeCheck = (props) => {
    return (
        <>
            <div className={styles.popupBackground}/>
           <RemoveScroll>
               <div className={styles.popupContainer}>
                   <div className={styles.header}>
                       Are you over 18?
                   </div>
                   <div className={styles.container}>
                       This website contains age-restricted content, including still and moving images. You must be 18 years old or older to enter it. By going beyond this point, you acknowledge that you are 18 or more.

                   </div>
                   <div className={styles.footer}>
                       <button className={styles.yes} onClick={()=>props.onClose()}>Yes</button>
                        <button className={styles.no} onClick={() => window.history.go(-1)}>No</button>
                   </div>
               </div>
           </RemoveScroll>
        </>
    );
};

export default AgeCheck;
