import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
// import TagManager from 'react-gtm-module'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
// const tagManagerArgs = {
//     gtmId: 'G-FQB5FVJWPR'
// }
// TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
      <App />
  </BrowserRouter>,
  rootElement);

