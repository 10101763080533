import React, { useCallback, useEffect, useState } from "react";
import ContactPopup from "../../../../Components/ContactPopup/ContactPopup";
import styles from "../../Layout/Header/Header.module.css";
import MobileHeaderLogo from "../../../../Components/Mobile/MobilHeaderLogo/MobileHeaderLogo";
import MobileSearch from "../../../../Components/Mobile/MobileSearch/MobileSearch";
// import MobileGamesMenu from "../../../../Components/Mobile/MobileGamesMenu/MobileGamesMenu";
import { RemoveScroll } from "react-remove-scroll";
import { NavLink } from "react-router-dom";
import MobileLeftMenu from "../../../../Components/Mobile/MobileLeftMenu/MobileLeftMenu";
import RedirectBtn from "../../../../Components/RedirectBtn/RedirectBtn";

const Header = () => {
  // const [showHeader, setShowHeader] = useState(true);
  // const [y, setY] = useState(window.scrollY);

  // const handleNavigation = useCallback(
  //   (currentEvent) => {
  //     const window = currentEvent.currentTarget;
  //     if (y > window.scrollY) {
  //       setShowHeader(true);
  //     } else if (y < window.scrollY) {
  //       setShowHeader(false);
  //     }
  //     setY(window.scrollY);
  //   },
  //   [y]
  // );

  // useEffect(() => {
  //   setY(window.scrollY);
  //   window.addEventListener("scroll", handleNavigation);

  //   return () => {
  //     window.removeEventListener("scroll", handleNavigation);
  //   };
  // }, [handleNavigation]);
  const [isContactPopupVisible, setIsContactPopupVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  function handleShowContactPopup() {
    setIsContactPopupVisible(true);
    setIsMenuVisible(false);
  }
  function handleSubMenu() {
    if (isSubMenuVisible) {
      setIsSubMenuVisible(false);
    } else {
      setIsSubMenuVisible(true);
    }
  }
  return (
    <>
      {isContactPopupVisible && (
        <ContactPopup onClose={() => setIsContactPopupVisible(false)} />
      )}
      {
        // showHeader && (
        <header className={styles.header + " " + styles.showHeader}>
          <MobileHeaderLogo />
          <div className={styles.headerRight}>
            <MobileSearch />
            <div
              className={styles.burgerIconWrapper}
              onClick={() => setIsMenuVisible(true)}
            >
              <div className={styles.burgerMenuIcon}></div>
            </div>
            {isMenuVisible && (
              <>
                <div
                  className={styles.staticBackground}
                  onClick={() => setIsMenuVisible(false)}
                />
                <RemoveScroll>
                  <div className={styles.rightMenuContainer}>
                    <div
                      className={styles.closeIconWrapper}
                      onClick={() => setIsMenuVisible(false)}
                    >
                      <div className={styles.closeIcon} />
                    </div>
                    <div className={styles.headerMenuItems}>
                      <NavLink
                        activeClassName={styles.activeMenuItem}
                        className={styles.headerMenuItem}
                        to={"about-us"}
                        onClick={() => setIsMenuVisible(false)}
                      >
                        About Us
                      </NavLink>
                      <NavLink
                        activeClassName={styles.activeMenuItem}
                        className={styles.headerMenuItem}
                        to={"partners"}
                        onClick={() => setIsMenuVisible(false)}
                      >
                        Partners
                      </NavLink>
                      {/* <NavLink
                        activeClassName={styles.activeMenuItem}
                        className={styles.headerMenuItem}
                        to={"meet-us"}
                        onClick={() => setIsMenuVisible(false)}
                      >
                        Meet Us
                      </NavLink> */}
                      <NavLink
                        activeClassName={styles.activeMenuItem}
                        className={styles.headerMenuItem}
                        to={"licenses"}
                        onClick={() => setIsMenuVisible(false)}
                      >
                        Licenses
                      </NavLink>
                      <div
                        className={
                          styles.headerMenuItem + " " + styles.withSubMenu
                        }
                        onClick={() => handleSubMenu()}
                      >
                        Games
                        {isSubMenuVisible && (
                          <div className={styles.subMenuItemsWrapper}>
                            <MobileLeftMenu onClose={() => {setIsMenuVisible(false)}} />
                            {/* <NavLink
                              className={
                                styles.subMenuItem + " " + styles.allItems
                              }
                              to={"/news?category=All"}
                              onClick={() => setIsMenuVisible(false)}
                            >
                              All
                            </NavLink>
                            <NavLink
                              className={styles.subMenuItem}
                              to={"/news?category=Releases"}
                              onClick={() => setIsMenuVisible(false)}
                            >
                              Releases
                            </NavLink>
                            <NavLink
                              className={styles.subMenuItem}
                              to={"/news?category=Promotions"}
                              onClick={() => setIsMenuVisible(false)}
                            >
                              Promotions
                            </NavLink>
                            <NavLink
                              className={styles.subMenuItem}
                              to={"/news?category=PartnerShips"}
                              onClick={() => setIsMenuVisible(false)}
                            >
                              PartnerShips
                            </NavLink>
                            <NavLink
                              className={styles.subMenuItem}
                              to={"/news?category=Awards"}
                              onClick={() => setIsMenuVisible(false)}
                            >
                              Awards
                            </NavLink>
                            <NavLink
                              className={styles.subMenuItem}
                              to={"/news?category=Events"}
                              onClick={() => setIsMenuVisible(false)}
                            >
                              Events
                            </NavLink>
                            <NavLink
                              className={styles.subMenuItem}
                              to={"/news?category=BlogPosts"}
                              onClick={() => setIsMenuVisible(false)}
                            >
                              BlogPosts
                            </NavLink>
                            <NavLink
                              className={styles.subMenuItem}
                              to={"/career"}
                              onClick={() => setIsMenuVisible(false)}
                            >
                              Career
                            </NavLink> */}
                          </div>
                        )}
                      </div>
                      {/* <div
                        className={styles.headerMenuItem}
                        onClick={() => handleShowContactPopup()}
                      >
                        Contact Us
                      </div> */}
                    </div>

                    <div className={styles.socialMediaWrapper}>
                      <a
                        href="https://www.instagram.com/smartsoftgaming"
                        target="_blank"
                        className={
                          styles.socialMediaItem + " " + styles.instagram
                        }
                      />
                      <a
                        href="https://twitter.com/SmartSoftGaming"
                        target="_blank"
                        className={
                          styles.socialMediaItem + " " + styles.twitter
                        }
                      />
                      <a
                        href="https://www.linkedin.com/company/smartsoft-gaming"
                        target="_blank"
                        className={
                          styles.socialMediaItem + " " + styles.linkedin
                        }
                      />
                      {/* <a
                        href="https://www.youtube.com/channel/UCjXNMFsvmHVPgvae_A0O0Xg"
                        target="_blank"
                        className={
                          styles.socialMediaItem + " " + styles.youtube
                        }
                      /> */}
                      <a
                        href="https://www.facebook.com/SmartSoftGaming/"
                        target="_blank"
                        className={
                          styles.socialMediaItem + " " + styles.facebook
                        }
                      />
                    </div>
                    <RedirectBtn type="sideMenu" />
                  </div>
                </RemoveScroll>
              </>
            )}
          </div>
        </header>
      }
      {/* <MobileGamesMenu /> */}
    </>
  );
};

export default Header;
