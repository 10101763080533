import React from 'react';
import styles from "./TermsAndConditions.module.css";
import {RemoveScroll} from "react-remove-scroll";

const TermsAndConditions = (props) => {
    return (
        <>
            <div className={styles.termsBackground} onClick={() => props.onClose()}/>
            <RemoveScroll>
                <div className={styles.termsContainer}>
                    <div className={styles.termsPopupHeader}>
                        <div className={styles.termsTitle}>Terms and conditions</div>
                        <img className={styles.closeTerms} src={'/assets/images/Close.png'} alt={'Close Icon'}
                             onClick={() => props.onClose()}/>
                    </div>
                    <iframe src="/terms/terms.html" className={styles.iframeContainer} frameBorder="0"/>
                    <button
                        className={styles.acceptTerms}
                        onClick={() => props.onClose()}>
                        Accept
                    </button>
                </div>
            </RemoveScroll>
        </>
    );
};

export default TermsAndConditions;
